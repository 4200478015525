import React, { Component } from "react";
import "./styles/app.style.css";
import { connect } from "react-redux";
import NavBarComponent from './components/standard/standardNavbar.component';
import MenuComponent from './components/standard/standardMenu.component';
import { Switch, Route } from 'react-router-dom';
import { ClaimPage } from './pages/claim.page';

const INITIAL_STATE = {
	isAppLoading: true,
	openMenu: false,
};

class App extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...INITIAL_STATE,
		};
	}

	componentDidMount = async () => {
		this.setState({ isAppLoading: false });
	};

	callbackOpenMenu = (param) => {
		this.setState({ openMenu: param });
	}

	render() {
		const { isAppLoading } = this.state;

		return (
			!isAppLoading && (
				<div>
					<NavBarComponent
						openMenu={this.state.openMenu}
						callbackOpenMenu={this.callbackOpenMenu}
					/>
					<MenuComponent openMenu={this.state.openMenu} callbackOpenMenu={this.callbackOpenMenu} />
					<Switch>
						<Route exact path={["/", "/claim"]} component={ClaimPage} />
					</Switch>
				</div>
			)
		)
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		isProviderConnected: state.wallet.isProviderConnected,
		provider: state.wallet.provider,
		web3: state.wallet.web3,
		selectedChainId: state.wallet.selectedChainId,
		selectedNetwork: state.wallet.selectedNetwork,
		selectedAddress: state.wallet.selectedAddress,
	};
};

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
