// eslint-disable-next-line import/no-anonymous-default-export
export default {
	translation: {
		locale: "es",
		"menu.resources": 'Recursos',
		"menu.sections": 'Secciones',
		"menu.social-media": 'Redes Sociales',

		"faqs.faq_title_1": 'How to stake GMM? ',
		"faqs.faq_subtitle_1": 'Step 1: Connect Wallet ',
		"faqs.faq_subtitle_1.text_1": 'Before staking, you have to ensure that you are connected with your Metamask wallet on the BSC network and have some BNB to afford the network fee.',
		"faqs.faq_subtitle_1.text_2": '<b>Caution</b>: Make sure to know how to use non-custodial wallets like Metamask before staking your tokens. As a rule of thumb, never give your seedphrase or private key to anyone and never interact with non-official websites.',
		"faqs.faq_subtitle_2": 'Step 2: Choose a Pool',
		"faqs.faq_subtitle_2.text_1": 'The Staking Platform has two pools available. Each pool has different APR rewards.',
		"faqs.faq_subtitle_2.text_2": 'The <b>GMM pool</b> is a single asset pool and requires GMM tokens.',
		"faqs.faq_subtitle_2.text_3": 'The <b>GMM/BUSD</b> pool requires Liquidity Provider (LP) tokens.',
		"faqs.faq_subtitle_2.text_4": 'Stakers can acquire GMM/BUSD LP tokens by providing liquidity in the official Pancakeswap pair:',
		"faqs.faq_subtitle_2.text_5": 'https://pancakeswap.finance/add/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/0x5B6bf0c7f989dE824677cFBD507D9635965e9cD3',
		"faqs.faq_subtitle_3": 'Step 3: Stake Your Funds',
		"faqs.faq_subtitle_3.text_1": 'Once you have required staking tokens (GMM for the GMM pool, and GMM-BUSD LP for the GMM-BUSD pool), you can stake them by selecting the appropriate amount and days you would like to stake in.',
		"faqs.faq_subtitle_3.text_2": '<b>Note</b>: If it’s your first stake, you will be prompted to do an approve transaction first.',
		"faqs.faq_subtitle_3.text_3": 'The platform requires a minimum lock duration of 1 month when depositing tokens. The longer the lock duration, the higher the yield rewards redeemed.',
		"faqs.faq_subtitle_3.text_4": 'Once you choose the lock period, approve the transaction. Keep in mind that your deposit and rewards will be locked during this period.',
		"faqs.faq_subtitle_4": 'Step 4: Accrue & Claim Rewards',
		"faqs.faq_subtitle_4.text_1": 'Once you successfully stake into the protocol, you will receive rewards after the staking period finalize.',
		"faqs.faq_subtitle_4.text_2": 'Rewards can be claimed on the ‘Your stakes’ page.',
		"faqs.faq_subtitle_5": 'Step 5: Withdraw Rewards & Deposits',
		"faqs.faq_subtitle_5.text_1": 'You can withdraw any unlocked rewards or deposits after the locking period of the stake.',

		"faqs.faq_title_2": "I've staked my GMM tokens. Can I stake more? ",
		"faqs.faq_text_2_1": 'Yes. You may stake as many times as you want. You just have to repeat the process.',

		"faqs.faq_title_3": "What are the rewards that I will receive? ",
		"faqs.faq_text_3_1": 'By staking GMM in either of the two liquidity pools, you will receive the following rewards:',
		"faqs.faq_text_3_2": '-	GMM.',
		"faqs.faq_text_3_3": '-	MST. Available to claim after the MST token public launch (Q3 2022)',
		"faqs.faq_text_3_4": '-	LANDs tickets. You can accumulate these tickets to change them for discounts on the purchase of future Gamium LANDs drops.',

		"faqs.faq_title_4": "What is the minimum stake amount?",
		"faqs.faq_text_4_1": 'The minimum stake amount is 1 GMM.',

		"faqs.faq_title_5": "What is the maximum stake amount?",
		"faqs.faq_text_5_1": 'There is no maximum stake amount.',

		"faqs.faq_title_6": "When will I start earning rewards?",
		"faqs.faq_text_6_1": 'You will start to earn rewards on the staked amount instantly.',

		"faqs.faq_title_7": "When will I receive my rewards?",
		"faqs.faq_text_7_1": 'Once the staking period ends, you will have to claim your deposit and all rewards generated through the “unstake” button in the ‘Your stakes’.',

		"faqs.faq_title_8": "What is the minimum lock duration?",
		"faqs.faq_text_8_1": 'The minimum lock duration is 1 month.',

		"faqs.faq_title_9": "What is the maximum lock duration?",
		"faqs.faq_text_9_1": 'The maximum lock duration is 12 months.',
	},
};

