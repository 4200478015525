import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from "redux-devtools-extension";

import thunk from 'redux-thunk';

import rootReducer from './reducers';

const initialState = {};

const middleware = [thunk];

// Log only in development
if (process.env.NODE_ENV === "development") {
  const { logger } = require("redux-logger");
  /*  middleware.push(logger); */
}

const store = createStore(
  // persistedReducer,
  rootReducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware)),
);

export default store;