import {
  CONTRACT_GET_STAKING_INFO,
} from "../actions/types";

const initialState = {
  staking: { quote: null, tlv_gmm: null, tlv_busd: null, 
      gmm: { total_deposits: null, max_lock_days: null, total_shares: null, share_bonus_per_year: null, reward_per_second: null }, 
      gmm_busd: { total_deposits: null, max_lock_days: null, total_shares: null, share_bonus_per_year: null, reward_per_second: null } 
  },
}
// Changes are made with pure functions (https://redux.js.org/understanding/thinking-in-redux/three-principles#changes-are-made-with-pure-functions)

function contractsReducer(state = initialState, action) {
  const { type, data } = action;

  switch (type) {
    /*
    case CREATE_LAND:
      return [...lands, data];
    */

    case CONTRACT_GET_STAKING_INFO:
      return { 
        ...state, 
        staking: data 
      }


    default:
      return state;
  }
};

export default contractsReducer;