import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

import es from "./es.js";
import en from "./en.js";

const resources = {
  es: es,
  en: en
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: "en",
    fallbackLng: 'en',
    /* debug: true, */
    interpolation: {
      escapeValue: false
    },
    react: {
      useSuspense: false
    },
    resources,
  });

export default i18n;