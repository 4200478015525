function getPrecision(num) {
    if (!isFinite(num)) return 0;
    let e = 1;
    let p = 0;
    while (Math.round(num * e) / e !== num) { e *= 10; p++; }
    return p;
  }
  
function sanitizeScientificNotation(num) {
    if (Math.abs(num) < 1.0) {
      const e = parseInt(num.toString().split('e-')[1], 10);
      if (e) {
        num *= 10 ** (e - 1);
        num = `0.${ (new Array(e)).join('0') }${num.toString().substring(2)}`;
      }
    } else {
      let e = parseInt(num.toString().split('+')[1], 10);
      if (e > 20) {
        e -= 20;
        num /= 10 ** e;
        num += (new Array(e + 1)).join('0');
      }
    }
    return num;
}

function abbreviateNumber(num, digits) {
  let units = ['k', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y'];
  let floor = Math.floor(Math.abs(num).toString().length / 3);
  let value=+(num / Math.pow(1000, floor))
  return value.toFixed(value > 1 ? digits:2) + units[floor - 1];
}

/*
 * Return the given number as a formatted string.  The default format is a plain
 * integer with thousands-separator commas.  The optional parameters facilitate
 * other formats:
 *   - decimals = the number of decimals places to round to and show
 *   - valueIfNaN = the value to show for non-numeric input
 *   - style
 *     - '%': multiplies by 100 and appends a percent symbol
 *     - '$': prepends a dollar sign
 *   - useOrderSuffix = whether to use suffixes like k for 1,000, etc.
 *   - orderSuffixes = the list of suffixes to use
 *   - minOrder and maxOrder allow the order to be constrained.  Examples:
 *     - minOrder = 1 means the k suffix should be used for numbers < 1,000
 *     - maxOrder = 1 means the k suffix should be used for numbers >= 1,000,000
 */
function formatNumber(
  number,
  {
    decimals = 0,
    valueIfNaN = "",
    style = "",
    useOrderSuffix = false,
    orderSuffixes = ["", "k", "M", "B", "T"],
    minOrder = 0,
    maxOrder = Infinity,
  } = {}
) {
  let x = parseFloat(number);

  if (isNaN(x)) return valueIfNaN;

  if (style === "%") x *= 100.0;

  let order;
  if (!isFinite(x) || !useOrderSuffix) order = 0;
  else if (minOrder === maxOrder) order = minOrder;
  else {
    const unboundedOrder = Math.floor(Math.log10(Math.abs(x)) / 3);
    order = Math.max(
      0,
      minOrder,
      Math.min(unboundedOrder, maxOrder, orderSuffixes.length - 1)
    );
  }

  const orderSuffix = orderSuffixes[order];
  if (order !== 0) x /= Math.pow(10, order * 3);

  return (
    (style === "$" ? "$" : "") +
    x.toLocaleString("en-US", {
      style: "decimal",
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals,
    }) +
    orderSuffix +
    (style === "%" ? "%" : "")
  );
}
  
export default getPrecision;
export { sanitizeScientificNotation, abbreviateNumber, formatNumber };