import React, { Fragment, useState } from 'react';
import '../../styles/standardNavbar.style.css';
import config from '../../config.json';
import { Nav, Dropdown } from 'react-bootstrap';
import WalletButtonComponent from '../../components/wallet/walletButton.component';
/* import { useSelector } from 'react-redux'; */
/* import WalletAddressInfo from '../wallet/walletAddressInfo.component'; */

export const NavBarComponent = ({ openMenu, callbackOpenMenu }) => {
	/* 	const isProviderConnected = useSelector((state) => state.wallet.isProviderConnected);
		const selectedAddress = useSelector((state) => state.wallet.selectedAddress); */
	const [selectedKey, setSelectedKey] = useState(window.location.pathname.length > 1 ? window.location.pathname : "/");
	const handleSelect = (eventKey) => setSelectedKey(eventKey);

	return (
		<Fragment>
			<div className='navbar-container-web'>
				<Nav id='navId' className='navbar-wrp' activeKey={selectedKey} onSelect={handleSelect}>
					<div className='left-side-wrp'>
						<a href='https://gamium.world'>
							<img className='navbar-logo-gamium' src={"images/logo-gamium.svg"} alt='GAMIUM' />
						</a>
					</div>
					<div className='nav-links-container'>
						<a href="https://gamium.world/products" rel="noreferrer">
							<span className='nav-link mr-35'>   Products</span>
						</a>
						<a href="https://gamium.world/governance" rel="noreferrer">
							<span className='nav-link mr-35'>	Governance</span>
						</a>
						<a href="https://careers.gamium.world" rel="noreferrer">
							<span className='nav-link mr-35'>	Careers</span>
						</a>
						<WalletButtonComponent />
					</div>
				</Nav>
			</div>
			<div className='navbar-container-mobile'>
				<Nav id='navId' className='navbar-mobile' activeKey={selectedKey} onSelect={handleSelect}>
					<section className='navbar-mobile-top'>
						<div>
							<a href='https://gamium.world'>
								<img className='navbar-logo-gamium' src={"images/logo-gamium.svg"} alt='GAMIUM' />
							</a>
						</div>
						<WalletButtonComponent />
					</section>
				</Nav>
				<section className='navbar-mobile-down'>
					<a href="https://gamium.world/products" rel="noreferrer">
						<span className='nav-link mr-35'>   Products</span>
					</a>
					<a href="https://gamium.world/governance" rel="noreferrer">
						<span className='nav-link mr-35'>	Governance</span>
					</a>
					<a href="https://careers.gamium.world" rel="noreferrer">
						<span className='nav-link mr-35'>	Careers</span>
					</a>
				</section>
			</div>
		</Fragment>
	);
};

export default NavBarComponent;
