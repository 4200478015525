import React from "react";
import { Button } from "react-bootstrap";
import '../styles/buttonClaim.style.css'

export const ButtonClaim = ({ textButton, onClickClaim }) => {
  return (
    <Button className="btn-primary" onClick={onClickClaim} >
      <span>{textButton}</span>
    </Button>
  );
};
