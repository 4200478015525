import { combineReducers } from "redux";

import walletReducer from "./wallet.reducer";
import sessionReducer from "./session.reducer";
import contractsReducer from "./contracts.reducer";

export default combineReducers({
  wallet: walletReducer,
  session: sessionReducer,
  contracts: contractsReducer,
});
