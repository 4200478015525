import React from "react";
import "../../styles/standardOverlay.style.css"

export const CustomOverlay = (props) => {

	const { children } = props;

	return <div className="modal-overlay">
		<div className="modal-overlay-wrp">
			{children}
		</div>
	</div>
};

export default CustomOverlay;