import React, { useState, useEffect, useCallback, Fragment } from "react";
import { Button } from 'react-bootstrap';
import { ButtonClaim } from '../components/buttonClaim.component';
import { useSelector } from 'react-redux';
import { formatNumber } from '../helpers/numbers';
import { shortWeb3Address } from '../helpers/formaters';
import { toChecksumAddress, fromWei } from 'web3-utils';
import config from '../config.json';
import Loader from '../components/standard/standardLoader.component';
import Claim_abi from '../abi/Claim_abi.json'
import CustomModal from '../components/standard/standardModal.component';
import CustomOverlay from '../components/standard/standardOverlay.component';

export const ClaimPage = () => {
  const web3 = useSelector((state) => state.wallet.web3);
  const selectedAddress = useSelector((state) => state.wallet.selectedAddress);
  const [hasPublicClaim, setHasPublicClaim] = useState(false);
  const [hasPrivateClaim, setHasPrivateClaim] = useState(false);
  const [gmmToClaimPublic, setGmmToClaimPublic] = useState(0);
  const [gmmToClaimPrivate, setGmmToClaimPrivate] = useState(0);
  const [isThisPageLoading, setIsThisPageLoading] = useState(true);
  const isProviderConnected = useSelector((state) => state.wallet.isProviderConnected);
  const [openOverlaySuccessClaim, setOpenOverlaySuccessClaim] = useState(false);
  const [openOverlayErrorClaim, setOpenOverlayErrorClaim] = useState(false);
  const [modal, setModal] = useState({
    show: false,
    showLoading: false,
    title: null,
    showDismissButton: false,
    callbackOnDismissButton: () => null,
    showCloseButton: false,
    closeButtonText: null,
    callbackOnCloseButton: () => null,
    text: null,
  });

  const checkIfClaimAvailable = useCallback(async () => {
    // if i already connected the wallet then call both contracts and check if have
    // some GMM to claim and then : set hasPublicClaim: true and  hasPrivateClaim: true in case that have something to claim
    try {
      const publicContract = new web3.eth.Contract(Claim_abi, config.communityContractAddress);
      const privateContract = new web3.eth.Contract(Claim_abi, config.privateContractAddress);
      const hasGMMtoClaimPublic = await publicContract.methods._addressCanClaim(selectedAddress).call({ from: toChecksumAddress(selectedAddress) });
      if (hasGMMtoClaimPublic) {
        const respClaimPublic = await publicContract.methods.releasable(selectedAddress).call({ from: toChecksumAddress(selectedAddress) });
        setGmmToClaimPublic(formatNumber(parseInt(fromWei(respClaimPublic.toString())), { useOrderSuffix: true, decimals: 0, maxOrder: 2 }));
        setHasPublicClaim(true)
      } else {
        setHasPublicClaim(false)
      }
      const hasGMMtoClaimPrivate = await privateContract.methods._addressCanClaim(selectedAddress).call({ from: toChecksumAddress(selectedAddress) });
      if (hasGMMtoClaimPrivate) {
        const respClaimPrivate = await privateContract.methods.releasable(selectedAddress).call({ from: toChecksumAddress(selectedAddress) });
        setGmmToClaimPrivate(formatNumber(parseInt(fromWei(respClaimPrivate.toString())), { useOrderSuffix: true, decimals: 0, maxOrder: 2 }));
        setHasPrivateClaim(true)
      } else {
        setHasPrivateClaim(false)
      }
    } catch (error) {
      //console.error('[checkIfClaimAvailable] :', error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAddress])

  const onClaimGMM = async (typeClaim) => {
    try {
      setIsThisPageLoading(true)
      // here code to call contracts and Claim the GMM
      if (typeClaim === "Public") {
        const publicContract = new web3.eth.Contract(Claim_abi, config.communityContractAddress);
        // eslint-disable-next-line no-unused-vars
        const respClaimPublic = await publicContract.methods.release(selectedAddress)
          .send({ from: toChecksumAddress(selectedAddress) })
          .once('sent', function (payload) {
            //console.log('[DEBUG][APPROVE] asking wallet for transaction');
            setModal({
              show: true,
              showLoading: true,
              title: 'Transaction pending user approval',
              text: 'Asking wallet for transaction'
            })
          })
          .once('sending', function (payload) {
            //console.log('[DEBUG][APPROVE] sending transaction');
            setModal({
              show: true,
              showLoading: true,
              title: 'Transaction pending user approval',
              text: 'Sending transaction ...'
            })
          })
          .once('transactionHash', function (hash) {
            //console.log('[DEBUG][APPROVE] received transaction hash: ' + hash);
            const linkHash = config.chainBlockExplorerUrls[0] + "tx/" + hash;
            setModal({
              show: true,
              showLoading: false,
              title: 'Transaction pending blockchain confirmation',
              text: <span>Received Tx hash: <a href={linkHash} target="_blank" rel='noreferrer'> {shortWeb3Address(hash, 8)}</a></span>,
            })
          }).then(async function (payload) {
            //console.log("Todo ok Public? ", payload)
            //if response of claim is OK show overlay success and then check again = checkIfClaimAvailable
            setModal({ show: false })
            setOpenOverlaySuccessClaim(true)
            await checkIfClaimAvailable();
            setIsThisPageLoading(false)
            return payload;
          })
      }

      if (typeClaim === "Private") {
        const privateContract = new web3.eth.Contract(Claim_abi, config.privateContractAddress);
        // eslint-disable-next-line no-unused-vars
        const respClaimPrivate = await privateContract.methods.release(selectedAddress)
          .send({ from: toChecksumAddress(selectedAddress) })
          .once('sent', function (payload) {
            //console.log('[DEBUG][APPROVE] asking wallet for transaction');
            setModal({
              show: true,
              showLoading: true,
              title: 'Transaction pending user approval',
              text: 'Asking wallet for transaction'
            })
          })
          .once('sending', function (payload) {
            //console.log('[DEBUG][APPROVE] sending transaction');
            setModal({
              show: true,
              showLoading: true,
              title: 'Transaction pending user approval',
              text: 'Sending transaction ...'
            })
          })
          .once('transactionHash', function (hash) {
            //console.log('[DEBUG][APPROVE] received transaction hash: ' + hash);
            const linkHash = config.chainBlockExplorerUrls[0] + "tx/" + hash;
            setModal({
              show: true,
              showLoading: false,
              title: 'Transaction pending blockchain confirmation',
              text: <span>Received Tx hash: <a href={linkHash} target="_blank" rel='noreferrer'> {shortWeb3Address(hash, 8)}</a></span>,
            })
          }).then(async function (payload) {
            //console.log("Todo ok Private ? ", payload)
            //if response of claim is OK show overlay success and then check again = checkIfClaimAvailable
            setModal({ show: false })
            setOpenOverlaySuccessClaim(true)
            await checkIfClaimAvailable();
            setIsThisPageLoading(false)
            return payload;
          })
      }

    } catch (error) {
      setModal({ show: false })
      setIsThisPageLoading(false)
      if (error.code !== 4001) {
        setOpenOverlayErrorClaim(true)
      }
    }
  }

  useEffect(() => {
    setIsThisPageLoading(true)
    async function execute() {
      if (isProviderConnected && selectedAddress) {
        //if we have a provider connected and a selectedAddress 
        //then we need to ask to the contracts for claim data available.
        await checkIfClaimAvailable();
        setIsThisPageLoading(false)
      }
      setIsThisPageLoading(false)
    }
    execute();
    //this effect will be executed when isProviderConnected and selectedAddress change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isProviderConnected, selectedAddress]);

  return (
    <Fragment>
      {openOverlaySuccessClaim
        && <CustomOverlay>
          <span>You have successfully claimed your vested $GMM, remember you can stake your $GMM in the Staking Dapp</span>
          <div className='w-100 d-flex justify-content-between mt-4'>
            <Button onClick={() => setOpenOverlaySuccessClaim(false)}>Close</Button>
            <Button onClick={() => window.location.href = "https://staking.gamium.world/"}>Go Staking</Button>
          </div>
        </CustomOverlay>
      }
      {openOverlayErrorClaim
        && <CustomOverlay>
          <span>Something went wrong.</span>
          <div className='w-100 d-flex justify-content-center mt-4'>
            <Button onClick={() => setOpenOverlayErrorClaim(false)}>Close</Button>
          </div>
        </CustomOverlay>
      }
      <CustomModal
        show={modal.show}
        title={modal.title}
        showLoading={modal.showLoading}
        showDismissButton={modal.showDismissButton}
        onDismissButtonCallback={modal.callbackOnDismissButton}
        showCloseButton={modal.showCloseButton}
        closeButtonText={modal.closeButtonText}
        onCloseButtonCallback={modal.callbackOnCloseButton}
      >
        {modal.text}
      </CustomModal>
      {!isThisPageLoading
        ? !isProviderConnected
          ? <div className='buttonClaim'>
            <span>You are not connected.</span>
          </div>
          : <section className='buttonClaim'>
            {hasPublicClaim &&
              <div className='d-flex flex-column mb-4'>
                <span className='mb-2'>Your wallet is whitelisted for claiming on the Community Sale</span>
                <ButtonClaim textButton={`Claim ${gmmToClaimPublic} GMM`} onClickClaim={() => onClaimGMM("Public")} />
              </div>
            }
            {hasPrivateClaim &&
              <div className='d-flex flex-column'>
                <span className='mb-2'>Your wallet is whitelisted for claiming on the Private Sale</span>
                <ButtonClaim textButton={`Claim ${gmmToClaimPrivate} GMM`} onClickClaim={() => onClaimGMM("Private")} />
              </div>
            }
            {!hasPublicClaim
              && !hasPrivateClaim
              && <div className='d-flex justify-content-center flex-column'>
                <span className='text-center mb-3'>You cannot claim GMM.</span>
                <span className='text-center'> Contact Gamium Team in case this is a mistake.</span>
              </div>}
          </section>
        :
        <Loader size={70} />
      }
    </Fragment>
  );
}